import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css'
import './App.css';
import Project from './Project';

const MINING_IMAGES = [
  { 
    original: require('./images/mining-project.png'),
    thumbnail: require('./images/mining-project.png')
  },
  { 
    original: require('./images/mining-alert.png'),
    thumbnail: require('./images/mining-alert.png')
  }
];

const MOBILE_IMAGES = [
  { 
    original: require('./images/mobile-project.png'),
    thumbnail: require('./images/mobile-project.png'),
    sizes:'(max-width: 320px) 280px, (max-width: 480px) 440px, 800px'
  }
];

const SPMS_IMAGES = [
  { 
    original: require('./images/spms-prototype.png'),
    thumbnail: require('./images/spms-prototype.png')
  },
  { 
    original: require('./images/spms-prototype-2.png'),
    thumbnail: require('./images/spms-prototype-2.png')
  }
];

const CLOUD_IMAGES = [
  { 
    original: require('./images/cloud-coding.png'),
    thumbnail: require('./images/cloud-coding.png')
  },
  { 
    original: require('./images/cloud-coding-2.png'),
    thumbnail: require('./images/cloud-coding-2.png')
  },
  { 
    original: require('./images/cloud-coding-3.png'),
    thumbnail: require('./images/cloud-coding-3.png')
  },
  { 
    original: require('./images/cloud-coding-4.png'),
    thumbnail: require('./images/cloud-coding-4.png')
  }
];

const KALAH_IMAGES = [
  { 
    original: require('./images/kalah-gif.gif'),
    thumbnail: require('./images/kalah-gif.gif')
  }
];

const BOARD_GAME_BOT_IMAGES = [
  { 
    original: require('./images/board-game-bot-wins.png'),
    thumbnail: require('./images/board-game-bot-wins.png')
  },
  { 
    original: require('./images/board-game-bot-users.png'),
    thumbnail: require('./images/board-game-bot-users.png')
  },
  { 
    original: require('./images/board-game-bot-menu.png'),
    thumbnail: require('./images/board-game-bot-menu.png')
  },
  { 
    original: require('./images/board-game-bot-add-user.png'),
    thumbnail: require('./images/board-game-bot-add-user.png')
  },
  { 
    original: require('./images/board-game-bot-remove-user.png'),
    thumbnail: require('./images/board-game-bot-remove-user.png')
  }
]


class Portfolio extends Component {

  render() {
    return (
      
      <div className="portfolio">

        <Project
          id="board-game-bot"
          title="Discord Board Game Stats Bot"
          description="During the COVID-19 pandemic everyone was forced to quarantine to reduce the spread of infection. Being a board game addict, I had to find other ways to play board games
            with my friends and family. I found a German site Brettspielwelt (Board Game World) which allowed us to play games over the internet for free. 
            They have an API that the UI calls to get user information such as games played, wins, etc.
            I created a discord server with my friends and built a bot to list out and rank users so we have some friendly competition.
            Over this project I found out that their API is not secure and is not up to date with today's standards. I couldn't believe it when creating a user they would send the username/password in plain text as a parameter in a GET request!"
            images={BOARD_GAME_BOT_IMAGES}
          tech={[
            "JavaScript / Node.js",
            "Brettspielwelt API"
          ]}
          link="https://github.com/jrunzer26/board-game-discord-bot"
          date="2020"
          colour="body-grey"
        />

      <Project
        id="real-estate"
        title="Real Estate Scanner (In progress)"
        description="A script triggered overnight to automatically calculate mortgage price, payments, maintenance, property tax, estimated rent and more from the houses 
          listed on realtor.ca.
          Each region to search for real estate is specified in the configuration file to allow users to easily expand their search.
          The tool can be used to filter out houses for investment purposes or for personal use.
          If the real estate is calculated to have a positive cashflow or is within the price range, it is flagged and the user can look into the listing with more detail."
          tech={[
          "Cron Jobs",
          "JavaScript / Node.js",
          "Realtor.ca API",
          "Excel"
        ]}
        date="2019"
        colour="body-white"
      />
      
      <Project
        id="crypto"
        title="Crypto Mining Health Check"
        description="A custom mining health check for my hobby GPU cryptocurrency mining rigs. 
          The health check is located on my Raspberry Pi on my internal network which will ping the rigs every 5 minutes and post the results to my website. 
          If the rigs are not responding or are under utilized, it will send me an automated email.
          This was a fun project I needed to build as my mining rigs were left unattended and it allowed me to have peace of mind that crypto was being generated."
        images={MINING_IMAGES}
        tech={[
          "Raspberry Pi",
          "Cron Jobs",
          "JavaScript / Node.js",
          "Digital Ocean Droplet",
          "ReactJs",
          "Nvidia-smi",
          "HTML / CSS"
        ]}
        date="2018"
        link="https://github.com/jrunzer26/CryptoMiningDashboard"
        colour="body-grey"
      />

      <Project
        id="cloud-coding"
        title="Coding In The Cloud"
        description="A school project to develop a cloud based Integrated Development Environment (IDE) to remove any barrier first year engineering students would have with setting up a local IDE. 
          Students would login with their UOIT Gmail account and save their programs on the cloud. Students could also join their professor and peers in a class to easily modify the programs which were created in the lectures/tuorials."
        images={CLOUD_IMAGES}
        tech={[
          "JavaScript / Node.js",
          "PostgreSQL",
          "HTML / CSS",
          "Amazon Web Services (AWS)",
          "Google Auth"
        ]}
        date="2018"
        link="https://github.com/jrunzer26/CloudCodingEnv"
        colour="body-white"
      />

      <Project
        id="meal-to-list"
        title="Meal to List"
        description="Meal to List is a distributed web application. Built for UOIT's distributed system course, this application allows users to search for interesting meal/snack recipes. 
        When they find something that they like, they can favorite the recipe and automatically add the ingredients on their todoist shopping list which is easily accessible from their phone."
        tech={[
          "JavaScript / Node.js",
          "Python",
          "EDAMAM API (Recipes)",
          "PostgreSQL",
          "HTML / CSS"
        ]}
        link="https://github.com/jrunzer26/DS-Project"
        date="2016/2017"
        colour="body-grey"
      />

      <Project 
        id="mobile-project"
        title="Territory War Age Alpha"
        description=
          "Territory War Age is a mobile king of the hill game where players can take over territory by discovering the real world around them. 
          Players fight for territory and become strong and powerful by buying soldiers to defend their land. 
          The front end was developed with Android Studio (Java) and the backend to calculate resources every hour & check who owns territory in the world was developed with Node.js/Javascript."
        images={MOBILE_IMAGES}
        challenges="The most challenging part of this project was learning how to use a players latitude and longitude to build a map of tiles around them. 
        It was not feasible to input all the tiles of the world in a database, so only when a player discovered the tiles they would be stored."
        tech={[
          "Google Maps",
          "Android",
          "Java",
          "JavaScript / Node.js",
          "PostgreSQL"
        ]}
        date="2016"
        link="https://github.com/jrunzer26/MobileProjectApp/wiki"
        colour="body-white"
      />

      <Project
        id="spms"
        title="Smart Playlist Management System (SPMS)"
        description="Smart Playlist Management System is a party music manager. 
        Using RFID technology, people can tap their RFID cards when entering a party to automatically shuffle music from their library into the mix.
        The SPMS system was built with an Arduino for RFID and a Raspberry Pi for the music manager."
        images={SPMS_IMAGES}
        tech={[
          "Raspberry Pi",
          "Arduino",
          "RFID Reader",
          "Python"
        ]}
        date="2016"
        link="https://github.com/jrunzer26/SPMS"
        colour="body-grey"
      />

      <Project
        id="kalah"
        title="Kalah/Mancala AI"
        description="Kalah/Mancala is a classic two player game where the objective is to have the most seeds(pieces) placed in their store.
          This was a 3rd year university project where students would build an AI to verse other classmates and ultimately determine their grades.
          The algorithm I developed played through the game breadth first and used heuristics to calculate the best move to score the most points.
          To test the algorithms developed, I created a bash script to run a random number generator algorithm against the developed algorithms. The algorithm with the most wins was submitted and came 3rd of 32 contestants with 47 wins, 14 losses and 1 tie."
        challenges="The most challenging part of this project was choosing which algorithm to submit for the competition. There are many different winning combinations for Kalah, so a script was developed to play different algorithms against a random number generator. Which ever one won the most (out of over 1000 - 2000 plays) was submitted."
          tech={[
          "Java",
          "Bash"
        ]}
        images={KALAH_IMAGES}
        date="2016"
        link="https://github.com/jrunzer26/KalahAI/"
        colour="body-white"
      />

      <Project
        id="audience-connect"
        title="Audience Connect"
        description="Audience connect was built to allow all types of students to be able to raise their voice in class. 
        From grade school to post secondary, students would be able to join classrooms for their class, ask questions and much more to the instructors in real time. 
        The code had fully maintained unit tests to ensure high quality code."
        tech={[
          "JavaScript / Node.js",
          "PostgreSQL",
          "gulp"
        ]}
        date="2015"
        link="https://github.com/Patman64/AudienceConnect"
        colour="body-grey"
      />
      
      </div>
    );
  }
}

export default Portfolio;