import React, { Component } from 'react';
import ContentPage from './ContentPage';
import { Menu, Container } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import './App.css';

class App extends Component {
  state = { activeItem: 'home'}

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  render() {
    const { activeItem } = this.state

    return (
      <div className="App body-grey">

        <Menu className="top fixed inverted">
          <Container text>
          <Menu.Item name='home' active={activeItem === 'home'} onClick={this.handleItemClick}>
            Home
          </Menu.Item>
          <Menu.Item name='portfolio' active={activeItem === 'portfolio'} onClick={this.handleItemClick}>
            Portfolio
          </Menu.Item>
          <Menu.Item name='contact' active={activeItem === 'contact'} onClick={this.handleItemClick}>
            Contact
          </Menu.Item>
          </Container>
        </Menu>

        <ContentPage activeItem={this.state.activeItem}/>
        
        <div id="footer">
          <a href="https://github.com/jrunzer26" target="_blank" rel="noopener noreferrer"><i className="fab fa-2x fa-github fa-inverse" /></a>
          <h7 id="footer-jason">Jason Runzer</h7>
          <a href="https://www.linkedin.com/in/jasonrunzer" target="_blank" rel="noopener noreferrer"><i className="fab fa-2x fa-linkedin fa-inverse" /></a>
        </div>
      </div>
    );
  }

}

export default App;
