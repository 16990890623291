import React, { Component } from 'react';
import { Grid, Header, List, Image } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import './App.css';

class Job extends Component {
  
  createList = (data) => {
    return this.createListItems(data);
  }

  createAchievements = (data) => {
    if (data){
    return (
      <Grid.Row>
        <Grid.Column>
          <Header as="h4">Achievements</Header>
          {this.createListItems(data)}
        </Grid.Column>
      </Grid.Row>
    );
    } else {
      return null;
    }
  }

  createListItems = (data) => {
    var table = [];
    data.forEach(element => {
      table.push(<List.Item>{element}</List.Item>);
    });
    return <List bulleted>{table}</List>;
  }

  render() {
    return (
      <div className="Job" id={this.id}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <Header as="h3">{this.props.title}</Header>
              <p><Image src={this.props.companyImage} size="mini" spaced/> {this.props.company}</p>
            </Grid.Column>
            <Grid.Column className="right aligned" width={8}>
              <Header as="h3">{this.props.timeline}</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as="h4">Responsibilities</Header>
              {this.createList(this.props.responsibilities)}
            </Grid.Column>
          </Grid.Row>
          {this.createAchievements(this.props.achievements)}
        </Grid>
        
      </div>
    );
  }

}

export default Job;
