import React, { Component } from 'react';
import Home from './Home';
import Portfolio from './Portfolio';
import Contact from './Contact';
import 'semantic-ui-css/semantic.min.css'
import './App.css';

class ContentPage extends Component {
  state = { activeItem: this.props}

  render() {
    const { activeItem } = this.props;
    let page;
    if (activeItem === 'home') {
      page = <Home />
    } else if(activeItem === 'portfolio') {
      page = <Portfolio />
    } else if(activeItem === 'contact') {
      page = <Contact />
    } else {
      page = <p>missing page</p>
    }
    return (
      <div className="ContentPage">
      {page}
      </div>
    );
  }
}

export default ContentPage;
