import React, { Component } from 'react';
import { Container, Grid, Header, List } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import './App.css';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

class Project extends Component {

  state = { isOpen: false}

  createImages = (images) => {
    if (images == null) return;
    return <ImageGallery 
      items={images}
      autoPlay={false}/>
  }

  createList = (data1) => {
    if (data1) {
      var table1 = [];
      data1.forEach(element1 => {
        table1.push(<List.Item key={element1}>{element1}</List.Item>);
      });
      return (
        <Grid.Row>
        <Grid.Column className="center aligned">
          <List bulleted>{table1}</List>
        </Grid.Column>
      </Grid.Row>
      );
    } else {
      return null;
    }
  }

  createChallenges = (challenges) => {
    if(challenges !== undefined) {
      return (
        <Grid.Row>
          <Grid.Column>
            <Header as="h5">Challenges</Header>
            <p>{challenges}</p> 
        </Grid.Column>
        </Grid.Row>
      );
    } else { 
      return null;
    }
  }

  render() {
    return (
      <div className={"Project left aligned padding " + this.props.colour} id={this.props.id}>
          <Container text>
            <Header as="h3">
            {this.props.link ? <a target="_blank" rel="noopener noreferrer" href={this.props.link} >{this.props.title}</a> : this.props.title}
            </Header>
            <p>{this.props.date}</p>
            <Grid>
              <Grid.Row>
                <Grid.Column width={10}>
                  <Header as="h4">Description</Header>
                  <p>
                    {this.props.description}
                  </p>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Header as="h5">Technology</Header>
                  {this.createList(this.props.tech)}
                </Grid.Column>
              </Grid.Row>
              {this.createChallenges(this.props.challenges)}
              {this.createImages(this.props.images)}
            </Grid>
          </Container>
      </div>
    );
  }

}

export default Project;
