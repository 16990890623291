import React, { Component } from 'react';
import Job from './Job';
import Duolingo from './Duolingo';
import 'semantic-ui-css/semantic.min.css'
import './App.css';
import jason_pic from './images/jason-website.png';
import cibc_image from './images/cibc.png';
import telmetrics_image from './images/telmetrics.png';
import { Header, Image, Grid, Container, List, Divider } from 'semantic-ui-react';


class Home extends Component {

  render() {

    return (
      <div className="home">
        <div id="jason" className="body-grey padding">
          <Container className="center aligned" text>
          <Header as="h1">Jason Runzer</Header>
          <Image id="jason-img" src={jason_pic} size='medium' circular />
          <Header as="h3">Software Engineer</Header>
          {/*
          <div id="quote-table">
          <Table>
            <Table.Body>
              <Table.Row>
                <Table.Cell textAlign="center">
                  <Header as="h4" id="quote"><i className="fas fa-quote-left" />  If your parents never had children, chances are you won't either.  <i className="fas fa-quote-right" /></Header>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign="right">
                  <Header as="h6">Dick Cavett</Header>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          </div>      
          */}
          </Container>
        </div>
        <div id="about" className="body-white padding">
          <Container className="left aligned" text>
            <Header as="h2">About Me</Header>
            <p>
              I am a software engineer currently developing/maintaining .NET applications and systems.
              I have experience developing applications, creating automated health checks for REST/SOAP APIs, task automation and test environment support/coordination.
            </p>
            <Header as="h3">Interests</Header>
            <p>
              As my interests evolved over time, I have recently dipped into the world of investing through stocks and cryptocurrency.
              I love swimming, snorkeling, golfing, beach volleyball and all inclusive vacations.
              I am passionate about my car (2017 Golf) that I purchased in May 2017.
            </p>
            <p>
              After going on a vacation to Cuba I was interested in learning Spanish.
              I have been studying the language with Duolingo since November 2018 so I can travel to Spanish speaking countries with confidence. 
              Check out my Duolingo profile below!
            </p>
            <Duolingo/>
          </Container>
        </div>
        <div id="school" className="body-grey padding">
          <Container text>
          <div className="left aligned">
          <Grid>
            <Grid.Row>
              <Grid.Column width={3}>
                <Header as="h2"><i className="fas fa-graduation-cap"></i>UOIT</Header>
              </Grid.Column>
              <Grid.Column className="right aligned" width={13}>
                <Header as="h2">University of Ontario Institute of Technology</Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={7}>
                <Header as="h5"> GPA: 4.17 / 4.3</Header>
                <p>Graduated with Honours</p>
              </Grid.Column>
              <Grid.Column width={9} className="right aligned">
                <Header as="h5">September 2013 - August 2017</Header>
              </Grid.Column>    
            </Grid.Row>            
          </Grid>
          <Header as="h3">Key Courses</Header>
          <List bulleted>
            <List.Item>Distributed Systems</List.Item>
            <List.Item>Data Structures</List.Item>
            <List.Item>Real Time Systems</List.Item>
            <List.Item>Design & Analysis of Algorithms</List.Item>
            <List.Item>Algorithms</List.Item>
          </List>
          </div>
          </Container>
        </div>
        <div id="experience" className="body-white left aligned padding">
          <Container text>
          <Header as="h2"><i className="far fa-building"></i> Experience</Header>
          <Job 
            title="Junior Software Developer"
            company="Telmetrics"
            companyImage={telmetrics_image}
            timeline="September 2019 - Present"
            responsibilities={[
              ".NET & AngularJS Application Development/Maintenance/Support", 
              "REST API Automated Test Suite Development",
              ]} />
          <Divider />
          <Job 
            title="Senior Technical Service Analyst"
            company="Canadian Imperial Bank of Commerce"
            companyImage={cibc_image}
            timeline="May 2017 - September 2019"
            achievements={[
              "CIBC Team Quarterly Achiever Q2 2019",
              "CIBC Individual Quarterly Achiever Q1 2019",
              "CIBC Individual Quarterly Achiever Q1 2018"]}
            responsibilities={[
              "Test Environment Management", 
              "C# .NET Internal Website Development/Maintenance/Support",
              "Health Check Development/Maintenance",
              "Microsoft Sharepoint Test Environment Support Automation",
              "Code Sync Up Coordination of over 15+ applications",
              "Incident Management",
              "Change Management",
              "Management of 1-2 Co-op students",
              "Test Environment Support Project Estimates"
              ]} />
          <Divider />
          <Job 
            title="Test Analyst"
            company="Canadian Imperial Bank of Commerce"
            companyImage={cibc_image}
            timeline="May 2016 - August 2016"
            responsibilities={["Health Check Development/Maintenance", 
              "C# .NET Internal Website Development"]} />
          </Container>
        </div>
        <div id="tools" className="body-grey left aligned padding">
          <Container text>
            <Header as="h2"><i className="fas fa-laptop"></i> Tools</Header>
            <Header as="h3">Markup/Programming Languages</Header>
            <List bulleted>
              <List.Item>JavaScript, Node.js</List.Item>
              <List.Item>ReactJS</List.Item>
              <List.Item>SQL</List.Item>
              <List.Item>HTML, CSS</List.Item>
              <List.Item>Java</List.Item>
              <List.Item>C#</List.Item>
              <List.Item>VBScript</List.Item>
            </List>

            <Header as="h3">Operating Systems</Header>
            <List bulleted>
              <List.Item><i className="fab fa-lg fa-windows"></i> Windows</List.Item>
              <List.Item><i className="fab fa-lg fa-linux"></i> Linux Mint - <a target="_blank" rel="noopener noreferrer" href="https://github.com/jrunzer26/i3">i3wm</a></List.Item>
            </List>
            <Header as="h3">Software</Header>
            <List bulleted>
              <List.Item>Visual Studio Code</List.Item>
              <List.Item>Visual Studio</List.Item>
              <List.Item>Parasoft SOAtest</List.Item>
              <List.Item>HP Unified Functional Testing (UFT)</List.Item>
            </List>
          </Container>
        </div>
      </div>
    );
  }
}

export default Home;