import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css'
import './App.css';
import { Header, Container, TextArea, Form, Button, Message } from 'semantic-ui-react';


class Contact extends Component {

  state = {
    name: '',
    email: '',
    message: '',
    formSuccess: false,
    formFailure: false
  }

  handleChange = (e, {name, value}) => {
    this.setState({[name]: value})
  }
  

  handleSubmit = () => {
    var emailMessage = {
      "message": this.state.message,
      "email": this.state.email,
      "name": this.state.name
    };
    fetch('/api/email', {
      method: 'post',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(emailMessage)
    }).then((response) => {
      console.log('email');
      console.log(response)
      if(response.status === 200) {
        this.setState({formSuccess : true});
      } else {
        this.setState({formFailure : true});
      }      
    }).catch((err) => {
      this.setState({formFailure : true});
    });
  }

  render() {

    return (
      <div className="contact content">
        <div className="body-grey">
          <Container className="center aligned" text>
            <Header as="h1">Contact Me</Header>  
            <Form className ="left aligned" onSubmit={this.handleSubmit} success={this.state.formSuccess} error={this.state.formFailure}>
                <Form.Field>
                  <label>Name</label>
                  <Form.Input placeholder='Name' name="name" value={this.name} onChange={this.handleChange}/>
                </Form.Field>
                <Form.Field>
                  <label>Email</label>
                  <Form.Input placeholder='Email' name="email" value={this.email} onChange={this.handleChange}/>
                </Form.Field>
                <Form.Field>
                  <label>Message</label>
                  <TextArea rows={10} name='message' value={this.message} onChange={this.handleChange} placeholder='Message' />
                </Form.Field>
                <Message success header='Email Sent' content="Thanks for your email. I'll try to get back to you as soon as possible!" />
                <Message error header='Error' content="An error occurred. Please try again later or leave me a message on my LinkedIn." />
                <Button type='submit'>Send</Button>
            </Form>
          </Container>
        </div>
      </div>
    );
  }
}

export default Contact;