import React, { Component } from 'react';
import { Image } from 'semantic-ui-react'
import duolingoImg from './images/duolingo-64.png'
import spainImg from './images/spain-flag-48.png'
import 'semantic-ui-css/semantic.min.css'
import './App.css';

class Duolingo extends Component {

  state = {
    userName: 'jrunzer26',
    streak: '0',
    language: 'Spanish',
    crowns: '0',
    level: '0',
    profileLink: 'https://duome.eu/jrunzer26'
  }

  componentDidMount(){
    this.createDuolingoProfile();
    console.log('duo mounted');
  }

  createDuolingoProfile = () => {
    fetch('/api/duolingo/profile', {
      method: 'get'
    })
    .then(response => response.json())
    .then((response) => {
      this.setState({
        userName: response.user,
        language: 'Spanish',
        streak: response.streak,
        level: response.level
      })
    });
  }

  render() {
    return (
      <div className="Duolingo">
        <p><Image className='mini' src={duolingoImg} spaced/><b>Duolingo</b><br></br> <a target="_blank" rel="noopener noreferrer" href={this.state.profileLink} >{this.state.userName}</a> - {this.state.language}<Image className='mini' src={spainImg} spaced/>- 
        Streak: {this.state.streak} <i className="fas fa-fire"></i> - Level: {this.state.level} <i className="fas fa-level-up-alt"></i></p>
      </div>
    );
  }
}

export default Duolingo;
